.history-card {
  transition: 0.8s all ease;
  padding: 1.2rem 0.75rem;
  border: 1px solid rgba(0, 0, 0, 0.11);
  .MuiCardHeader-root {
    padding: 0.75rem 0 0;
  }
  p {
    font-size: 16px;
    font-weight: 600;
    color: #d53833;
    &:nth-child(1) {
      color: gray;
      font-weight: 400;
    }
  }
}

.history-no-record {
  padding: 1rem;
}
