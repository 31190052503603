.profile {
    .profile-img {
        width: 350px;
        height: 350px;
        border-radius: 10px;
        background-image: url('../../../assets/img/testimonials/testimonials-5.jpg');
        background-position: center;
        background-size: cover;
    }
    .card{
        width: 350px;
    }

    .edit-profile {
        width: 350px;
        height: 350px;
        border-radius: 10px;
        background-color: rgba($color: #D53833, $alpha: 0.8);
        display: none;
        align-items:center;
        justify-content: center;
        color: white;
        font-size: 18px;
        font-weight: bold;
    }

    .profile-img:hover {
        cursor: pointer;

        .edit-profile {
            display: flex;
            flex-direction: column;
        }
    }

}