.home_page {
  .profile-card {
    transition: 0.8s all ease;
    padding: 0.75rem;
    border: 1px solid rgba(0, 0, 0, 0.11);
    .MuiCardHeader-root {
      padding: 0.75rem 0 0;
    }
    .profile-btn {
      display: block;
      text-align: left;
    }
  }

  .profile-no-record {
    padding: 1rem 0 0.2rem;
  }

  .profile-card:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
  }
  .view-more {
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    margin-left: 0.5rem;
    &:hover {
      color: #d53833;
    }
  }
}
