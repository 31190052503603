.view_profile {
  .vp-flex {
    display: flex;
    justify-content: space-between;
  }
  .profile_details {
    padding: 64px 0 20px;
    width: 100%;
  }

  .profile_image {
    padding-top: 10px;
    width: 300px;
    .profile_basic_details {
      position: fixed;
      width: 300px;
      p{
        text-overflow: ellipsis;
        height: 210px;
        overflow-y: scroll;
      }
    }
  }

  .other_images {
    .col-sm-4 {
      margin-bottom: 15px;
    }
  }
}
