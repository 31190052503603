.admin-dashboard{
    .card{
        background-color: whitesmoke;
    }
    .active{
       border-left: 10px solid green;
    }
    .new{
        border-left: 10px solid darkblue;
    }
    .inactive{
        border-left: 10px solid maroon;
    }
}
