.edit-preferences {
    .slider_label_right {
        margin-right: 20px;
        width: 80px;
    }
    .slider_label_left {
        margin-left: 20px;
        width: 80px;
    }


    .save_btn {
        position: fixed;
        right: 20px;
        bottom: 20px;
    }
}