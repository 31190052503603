body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.primaryColor{
  color:#D53833
}
.secondaryColor{
  color:#F7C254
}
button{
  text-transform: none!important;
  font-weight: 500!important;
}
.align-right{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.activeColor{
  color: green!important;
}
.inActiveColor{
  color: red!important;
}