.edit-profile {
  .save_btn {
    position: fixed;
    right: 20px;
    bottom: 20px;
  }

  .image-container {
    display: flex;
    flex-direction: column;
  }

  .image-list {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 25px;
  }

  .dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;
  }

  .uploaded-image {
    width: 200px;
    height: 200px;
    margin-right: 20px;
    border-radius: 4px;
    margin-bottom: 20px;
    position: relative;
    border: 1px solid rgba($color: #000000, $alpha: 0.3);
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

    img {
      width: 200px;
      height: 200px;
    }

  }
}

.dropzone {
  text-align: center;
  padding: 20px;
  border: 3px blue dashed;
  margin: auto;
  .dropzone-content {
    margin: 0;
  }
}

.file-list {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: center;
  width: auto;
  padding: 10px 20px;
  margin: 20px 0;
  border: 3px #eeeeee dashed;
  .file-item{
    position: relative;
    margin-top: 1rem;
  }
  img {
    height: 100%;
    width: 100px;
    padding-right: 10px;
    object-fit: cover;
  }

  .clear-btn {
    position: absolute;
    right: 5px;
    top: -16px;
    background: #ccc;
    svg{
        font-size: 1rem !important;
    }
  }
}

