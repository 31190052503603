.thanks-content {
  width: 100%;
  background: #fff;
  height: 100%;
  //   box-shadow: 0 10px 16px 1px rgba(174, 199, 251, 1);

  box-shadow: 4px 8px 40px 8px rgba(88, 146, 255, 0.2);
  .tc-1 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .tc-2 {
      padding: 30px;
      text-align: center;
      h1 {
        font-family: "Kaushan Script", cursive;
        font-size: 4em;
        letter-spacing: 3px;
        color: #d53833;
        margin: 0;
        margin-bottom: 20px;
      }
      p {
        margin: 0;
        font-size: 1.3em;
        color: #aaa;
        font-family: "Source Sans Pro", sans-serif;
        letter-spacing: 1px;
        b {
          color: #d53833;
        }
      }
      .go-home {
        color: #fff;
        background: #d53833;
        border: none;
        padding: 10px 50px;
        margin: 30px 0;
        border-radius: 30px;
        text-transform: capitalize;
      }
    }
  }
}

@media (min-width: 360px) {
  .thanks-content .tc-1 .tc-2 h1 {
    font-size: 4.5em;
  }
  .go-home {
    margin-bottom: 20px;
  }
}

@media (min-width: 600px) {
  .thanks-content {
    max-width: 1000px;
    margin: 0 auto;
  }
  .tc-1 {
    margin: 0 auto;
    margin-top: 50px;
  }
}
