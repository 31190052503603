.grid-container{
    display: grid;
    gap: 100px 90px;
    align-items: center;
    grid-template-columns: 30% 30% 15% ;
    height: 80px;
    padding: auto;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}



@media (max-width: 800px) {
    .grid-container{
        display: grid;
        gap: 10px 90px;
        align-items: center;
        grid-template-columns: auto;
        height: auto;
        align-content: space-around;
        padding: 20px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
    }
}