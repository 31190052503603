.subscription {
  .container {
    display: flex;
    margin: 20px auto;
  }
  h1 {
    text-align: center;
  }

  .pricing-card {
    background: #fff;
    border: none;
    border-radius: 8px;
    flex: 1;
    margin: 10px;
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem 1.5rem;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
    .pc-first,
    .pc-prices {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        color: #222;
        font-weight: 600;
        font-size: 14px;
      }
    }
    .pc-second {
      span {
        display: block;
        color: #222;
        font-size: 14px;
      }
    }
    .pc-third {
      border-top: 1px solid #222;
      padding-top: 0.7rem;
      margin-top: 4rem;
      .pc-prices {
        &:nth-child(2) {
          p {
            color: rgb(61, 133, 61);
          }
        }
      }
    }
    .pc-fourth {
      display: flex;
      flex-direction: column;
      align-items: center;
      button {
        display: block;
        text-align: center;
        position: relative;
        background: none;
        padding: 0.5rem;
        font-size: 14px;
        font-weight: bold;
        color: #fff;
        width: 90%;
        border-radius: 18px;
        border: none;
        background: #d53833;
        margin: 1rem 0 0.7rem;
      }
      p {
        color: #222;
        font-size: 14px;
      }
    }
  }

  .pricingCard.halfYearly {
    .title {
      border-top: 6px solid #1abc9c;
    }
    .bttn {
      border: 3px solid #1abc9c;
      color: #1abc9c;
      &:hover {
        background: #1abc9c;
        color: #fff;
      }
    }
  }
  .pricingCard.monthly {
    .title {
      border-top: 6px solid #3498db;
    }
    .bttn {
      border: 3px solid #3498db;
      color: #3498db;
      &:hover {
        background: #3498db;
        color: #fff;
      }
    }
  }
  .pricingCard.yearly {
    .title {
      border-top: 6px solid #9b59b6;
    }
    .bttn {
      border: 3px solid #9b59b6;
      color: #9b59b6;
      &:hover {
        background: #9b59b6;
        color: #fff;
      }
    }
  }

  .switch-field {
    display: flex;
    margin-bottom: 36px;
    justify-content: center;
    overflow: hidden;
    input {
      position: absolute !important;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      width: 1px;
      border: 0;
      overflow: hidden;
    }
    label {
      background-color: #e4e4e4;
      color: #222;
      font-size: 14px;
      line-height: 1;
      text-align: center;
      padding: 12px 24px;
      margin-right: -1px;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      transition: all 0.1s ease-in-out;
      &:hover {
        cursor: pointer;
      }
      &:first-of-type {
        border-radius: 4px 0 0 4px;
      }
      &:last-of-type {
        border-radius: 0 4px 4px 0;
      }
    }
    input:checked + label {
      background-color: #d53833;
      color: #fff;
      box-shadow: none;
    }
  }
}
