.agreement {
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 100px 0 50px;
  h1,
  h2 {
    color: #d53833;
    font-family: "Poppins", sans-serif;
  }
  p{
    padding-bottom: 1.5rem;
    font-family: "Poppins", sans-serif;
  }
}
