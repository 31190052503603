.login-container {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
    overflow-y: hidden;

    .formField {
        margin-top: 30px;
    }

    .button {
        margin-top: 30px;
        width: 100%;
    }

    .forgotpwd {
        margin-top: 10px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        text-decoration: none;
        color: gray;
        font-size: 14px;
        font-weight: 500;
    }

    .heading1 {
        line-height: 4rem;
        font-size: 40px;
    }

    .heading2 {
        line-height: 2rem;
        font-size: 20px;
    }

    .para {
        color: gray;
        font-weight: 500;
        font-size: 12px;
    }

    .newuser {
        margin-top: 10px;

        span {
            font-size: 15px;
        }
    }


    .primaryColor:hover {
        cursor: pointer;
    }

    form {
        width: 100%;
    }

    .full-width {
        width: 100%;
        margin-bottom: 20px;
    }

    .center {
        text-align: center;
    }

    .info-text {
        letter-spacing: 1px;
    }

    .info-text a {
        color: #D53833;
        font-weight: 600;
        letter-spacing: 2px;
    }

    .secondary-color {
        color: #D53833;
    }

    .heading {
        font-weight: 500;
    }

    .align-center {
        padding: 0px 6%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: relative;
    }

    .login-bg-image {
        width: 100%;
        height: 100vh;
        background-image: url('../../assets/wedding.gif');
        background-size: 70%;
        background-position: center;
        background-repeat: no-repeat;
        transition: 0.8s all ease;
    }

}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .login-container {
        display: block;
        height: 100%;
        overflow: auto;

        .login-bg-image {
            height: 200px;
            background-size: cover;
        }
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 768px) {
    .login-container {
        display: block;
        height: 100%;
        overflow: auto;

        .login-bg-image {
            height: 300px;
            background-size: cover;
        }
    }
}


/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
    .login-container {
        display: block;
        height: 100%;
        overflow: auto;

        .login-bg-image {
            height: 300px;
            background-size: cover;
        }
    }
}